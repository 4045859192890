// src/stores/user.js
import { defineStore } from 'pinia';
import {ref,computed} from 'vue';

import type { User } from '@/types/User';

import * as Sentry from '@sentry/vue';
import {jwtDecode} from 'jwt-decode';

export const useSessionStore = defineStore('session',()=>{
  
    const token = ref<string | null>(null);

    const loadToken = ()=>{
        const tmp = window.localStorage.getItem('smt-token');
        if(tmp){
            token.value = tmp;
        }
    }

    const setToken = (_token: string)=>{
        

        try {
            localStorage.setItem('smt-token', _token);
        } catch (e) {
            if (e.name === 'QuotaExceededError') {
                console.error('Storage quota exceeded. Clearing localStorage to free up space.');                
                localStorage.clear();                
                try {
                    localStorage.setItem('smt-token', _token);
                } catch (e) {
                    console.error('Failed to set item even after clearing localStorage:', e);
                }
            } else {
                console.error('Error setting item in localStorage:', e);
            }
        }


        token.value = _token;

            const data  = jwtDecode(token.value) as {exp: number;};

            Sentry.setTag('user_role', data.group_id);
            Sentry.setTag('server', data.serverBase);
            const user = {
                id: data.sub, // Deixe isso dinâmico com base no JWT ou dados do usuário
                username: data.name,
                name: data.name, 
                display: data.name
            };

                  
            Sentry.setUser(user);
        
    }

    const clearSession = ()=>{
      token.value = null;
      window.localStorage.clear();
    }

    const checkSession = ()=>{
       
        return new Promise((resolve)=>{
            if(!!token.value){
                
                try {
                    const data  = jwtDecode(token.value) as {exp: number;};

                    

                       Sentry.setTag('user_role', data.group_id);
                       Sentry.setTag('server', data.serverBase);
                        const user = {
                          id: data.sub, // Deixe isso dinâmico com base no JWT ou dados do usuário
                          username: data.name,
                          name: data.name,
                          display: data.name
                        };

                  
                        Sentry.setUser(user);
                    


                    if(data.exp < (Date.now() / 1000)){

                        clearSession();

                        resolve(false);
                    }else{
                        resolve(true);
                    }

                } catch (error) {
                    console.error('Invalid JWT:', error);
                    resolve(false);
                }
            }else{
               resolve(false);
            }
        })
        
    }

    const isAuthenticated = computed(()=>{
        return !!token.value;
    });


    const user = computed(()=>{
        try{
            return jwtDecode(token.value ?? "" as string) as User;
        }catch(err){
            console.log(err);

            return false;
        }
    })
 

    loadToken();
    return {isAuthenticated,checkSession,setToken,user,token}
});
