import { createI18n } from 'vue-i18n';


const i18n = createI18n({
  legacy: false, // Desabilite o modo legacy
  fallbackLocale: 'ptBR',
  locale: 'ptBR',
  messages: {}, // Inicialmente vazio
});


  export async function loadLocaleMessages(locale) {
    const messages = await fetch(`/alpha/locales/${locale}.json`)
      .then(response => response.json())
      .catch(() => null);
    return messages;
  }

  
  
  export async function prepareLocale(locale) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await loadLocaleMessages(locale);


      if (messages) {
        i18n.global.setLocaleMessage(locale, messages);
      }
    }

  }

  
  export async function setLocale(locale) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await loadLocaleMessages(locale);


      if (messages) {
        i18n.global.setLocaleMessage(locale, messages);
        i18n.global.locale.value = locale;

    
      }
    } else {
      i18n.global.locale.value = locale;
    }

  }

export default i18n;
